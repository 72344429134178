import { Drawer, IconButton, makeStyles, Grid, styled, Link } from '@material-ui/core'
import React, { useState, useContext } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { Close } from '@material-ui/icons'
import styles from './MenuMobile.style'
import { AuthContext } from '../../contexts'
import Logo from '../../shared/imgs/farol-logo-novo.svg'
import LoggedUserInfo from '../Navbar/components/LoggedUserInfo/LoggedUserInfo'
import MobileMenuItem from './MenuMobileItem'

const useStyles = makeStyles(styles)

const MenuMobile = ({ menuClasses, menuOptions }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const classes = useStyles()
  const { currentUser } = useContext(AuthContext)

  const handleHamburgerClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleMenuClose = () => {
    setIsMenuOpen(false)
  }

  const StyledDrawer = styled(Drawer)(() => ({
    '& .MuiDrawer-paper': {
      backgroundColor: 'white',
      width: '100%',
    },
  }))

  return (
    <Grid xs={12}>
      <IconButton onClick={handleHamburgerClick} edge="start" color="secondary" aria-label="menu">
        <MenuIcon />
      </IconButton>
      <StyledDrawer anchor="left" open={isMenuOpen} onClose={handleMenuClose}>
        <Grid xs={12}>
          <Grid xs={12} style={{ flexDirection: 'row' }} className={menuClasses.container}>
            <Grid xs={12}>
              <Link href="/">
                <img alt="Logo Senna" src={Logo} className={menuClasses.logo} />
              </Link>
            </Grid>
            <Grid xs={12} className={classes.leftIconContainer}>
              <IconButton onClick={handleHamburgerClick} color="secondary" aria-label="menu">
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
            {currentUser && <LoggedUserInfo />}
          </Grid>
          <Grid
            xs={12}
            style={{
              marginBottom: 20,
            }}
          >
            {menuOptions.map(option => {
              return (
                <MobileMenuItem
                  option={option}
                  action={() => {
                    option.action()
                    handleMenuClose()
                  }}
                />
              )
            })}
          </Grid>
        </Grid>
      </StyledDrawer>
    </Grid>
  )
}

export default MenuMobile
