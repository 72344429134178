export default function styles() {
  return {
    leftIconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '-15px',
    },
    optionContainer: {
      padding: '17px 36px 17px 36px',
      borderBottom: '1px solid #E6E6E6',
    },
    optionTitle: {
      color: '#858585',
      fontSize: 16,
      width: '100%',
    },
  }
}
