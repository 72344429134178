import { Grid, Menu, styled, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import HeaderMenuItemChild from './HeaderMenuItemChild'
import { HeaderContext } from '../../../../contexts'

const HeaderMenuItem = ({ option, classes }) => {
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [showDot, setShowDot] = useState(false)
  const [highlightOption, setHighlightOption] = useState(false)
  const { currentPath } = useContext(HeaderContext)

  const open = Boolean(menuAnchor)
  const handleClick = e => {
    if (option.children.length > 0) {
      setMenuAnchor(e.currentTarget)
    } else {
      option.action()
    }
  }
  const closeDropdownMenu = () => {
    setMenuAnchor(null)
  }
  const StyledMenu = styled(Menu)(() => ({
    '& .MuiPaper-root': {
      borderRadius: '0px 0px 4px 4px',
      marginTop: 19,
      boxShadow: '0px 13px 16px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
  }))

  useEffect(() => {
    if (option.highlightPaths && option.highlightPaths.length > 0) {
      const hasOption = option.highlightPaths.filter(path => {
        return currentPath.includes(path)
      })
      setHighlightOption(
        option.title === 'Início'
          ? option.highlightPaths.includes(currentPath)
          : hasOption.length > 0,
      )
    } else {
      setHighlightOption(false)
    }
  }, [currentPath, option])

  return (
    <>
      <Grid
        onClick={handleClick}
        className={classes.menuItemContainer}
        onMouseEnter={() => setShowDot(true)}
        onMouseLeave={() => setShowDot(false)}
      >
        <Typography
          style={{
            color: open || showDot || highlightOption ? '#003C78' : '',
            borderBottom: highlightOption ? '3px solid #003C78' : '',
          }}
          className={classes.menuItemTitle}
        >
          {option.title}
        </Typography>
        <Grid className={classes.dotContainer}>
          <Grid
            className={classes.dot}
            style={{
              visibility: open || showDot ? 'visible' : 'hidden',
            }}
          />
        </Grid>
      </Grid>
      {option.children.length > 0 && (
        <StyledMenu
          id="basic-menu"
          open={open}
          anchorEl={menuAnchor}
          disableScrollLock
          onClose={closeDropdownMenu}
          anchorReference={menuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {option.children.map(child => {
            return (
              <HeaderMenuItemChild
                child={child}
                closeDropdownMenu={closeDropdownMenu}
                classes={classes}
              />
            )
          })}
        </StyledMenu>
      )}
    </>
  )
}

export default HeaderMenuItem
