const applicationTabs = {
  SENNA: {
    type: 'SELECT_SENNA',
    label: 'Senna',
    tab: 0,
    url: 'assessments',
    fetchUrl: 'assessments',
  },
  DIALOGUES: {
    type: 'SELECT_DIALOGUES',
    label: 'Diálogos socioemocionais',
    tab: 1,
    url: 'farol-aplicacoes-dialogos/dialog-assessments',
    fetchUrl: 'farol-aplicacoes-dialogos/dialog-assessments',
  },
  WORK_WORLD_TOOL: {
    type: 'SELECT_WORK_WORLD_TOOL',
    label: 'Realizando Escolhas',
    tab: 2,
    url: 'work-world-tool/filter',
    fetchUrl: 'work-world-tool',
  },
  SENNA_KIDS: {
    type: 'SELECT_SENNA_KIDS',
    label: 'Senna para crianças',
    tab: 3,
    url: 'senna-kids-assessments',
    fetchUrl: 'researcher-assessments/senna-kids-profile',
  },
  SENNA_ADULT: {
    type: 'SELECT_SENNA_ADULT',
    label: 'Senna adultos',
    tab: 4,
    url: 'senna-adult-assessments',
    fetchUrl: 'senna-adults-assessments',
  },
}

export default applicationTabs
