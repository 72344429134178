import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Header from './Header'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: '#00000033 1px 1px 10px 1px',
    height: '90px',
    background: 'white',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: '14px',
  },
}))

export default function Navbar({ history }) {
  const classes = useStyles()

  return (
    <AppBar position="fixed" className={classes.root}>
      <Header history={history} />
    </AppBar>
  )
}
