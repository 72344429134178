import PropTypes from 'prop-types'
import React, { createContext, useState } from 'react'

export const HeaderContext = createContext({
  currentPath: null,
  setCurrentPath: () => {},
})

export const HeaderContextProvider = ({ children }) => {
  const [currentPath, _setCurrentPath] = useState(null)

  const setCurrentPath = path => {
    _setCurrentPath(path)
  }

  const value = {
    currentPath,
    setCurrentPath,
  }

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
}

export default HeaderContextProvider

HeaderContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
