import { useEffect, useState } from 'react'

import { useSnackbar } from '../../../shared/hooks'
import { getData } from '../../../utils/fetchData/fetchData'
import { PageLocation } from '../../../utils/PageLocation'
import { getSelectedProfile } from '../../../utils/user/profileHelper'

const useFilterOptions = () => {
  const [state, setState] = useState([])

  const getFilterOptions = requestParams => {
    getData(requestParams).then(data => {
      const isDataList = data && Array.isArray(data)
      const isDataWithContent = data && data.content && Array.isArray(data.content)

      if (isDataList) {
        setState(data)
      } else if (isDataWithContent) {
        setState(data.content)
      }
    })
  }

  return { state, setState, getFilterOptions }
}

const useTeachingNetworksFilterOptions = currentUser => {
  const { state: teachingNetworks, getFilterOptions: getTeachingNetworks } = useFilterOptions()
  const [isLoading, setLoading] = useState(false)
  const { snackbarError } = useSnackbar()

  useEffect(() => {
    if (!currentUser || !currentUser.teachingNetwork || isLoading) {
      return
    }

    const { id } = currentUser.teachingNetwork

    const requestParams = {
      url: `teaching-networks/network-manager-at/${id}`,
      snackbarError: () => snackbarError('Falha ao buscar as redes, tente atualizar a página'),
      setLoading,
    }

    getTeachingNetworks(requestParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return {
    teachingNetworks,
    isTeachingNetworksLoading: isLoading,
  }
}

const useSchoolsFilterOptions = (currentUser, isImplementador, formFilterValues, setFieldValue) => {
  const {
    state: schoolList,
    setState: setSchools,
    getFilterOptions: getSchools,
  } = useFilterOptions()

  const regionalIds = localStorage.getItem('regionalIds')
    ? JSON.parse(localStorage.getItem('regionalIds'))
    : undefined

  const [isLoading, setLoading] = useState(false)
  const { snackbarError } = useSnackbar()

  useEffect(() => {
    setFieldValue('schools', [])
    setSchools([])
    if (currentUser !== undefined) {
      const params = {}
      params.profileId = getSelectedProfile().id
      params.userTeachingNetworkId = currentUser.teachingNetwork?.id
      params.teachingNetworkIds =
        formFilterValues.teachingNetworks && formFilterValues.teachingNetworks.length > 0
          ? formFilterValues.teachingNetworks.map(s => s.id).join(',')
          : undefined
      params.pageLocation = PageLocation.PROGRAM
      params.associatedRegionalIds = regionalIds ? regionalIds.map(r => r).join(',') : undefined

      const request = {
        url: `/schools/filter/associated`,
        options: { params },
        snackbarError,
        setLoading,
      }

      getSchools(request)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFilterValues.teachingNetworks])

  return {
    schoolList,
    isSchoolsLoading: isLoading,
  }
}

const useClassroomsFilterOptions = (profile, formFilterValues, setFieldValue) => {
  const {
    state: classroomList,
    setState: setClassrooms,
    getFilterOptions: getClassrooms,
  } = useFilterOptions()
  const [isLoading, setLoading] = useState(false)
  const { snackbarError } = useSnackbar()

  const hasSelectedSchool = formFilterValues?.schools?.length > 0

  useEffect(() => {
    setFieldValue('classrooms', [])
    setClassrooms([])

    if (!hasSelectedSchool) {
      return
    }

    const { id } = profile
    const params = {
      profileId: id,
      schoolIds: formFilterValues.schools.map(s => s.id).join(','),
      size: 100,
    }

    const requestParams = {
      url: `/classrooms/school-list`,
      options: { params },
      snackbarError: () => snackbarError('Falha ao buscar as turmas, tente atualizar a página'),
      setLoading,
    }

    getClassrooms(requestParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFilterValues.schools])

  return {
    classroomList,
    isClassroomsLoading: isLoading,
  }
}

const useGradesFilterOptions = classrooms => {
  const { state: gradeList, setState: setGrades } = useFilterOptions()

  useEffect(() => {
    const gradesFiltered = classrooms
      .map(classroom => classroom.grade)
      .filter((value, index, self) => {
        const gradesId = self.map(grade => grade.id)
        return gradesId.indexOf(value.id) === index
      })
      .sort()

    setGrades(gradesFiltered)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classrooms])

  return { gradeList }
}

export {
  useSchoolsFilterOptions,
  useClassroomsFilterOptions,
  useTeachingNetworksFilterOptions,
  useGradesFilterOptions,
}
