import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import styles from './MenuMobile.style'

const useStyles = makeStyles(styles)

const MobileMenuItem = ({ option, action }) => {
  const { title } = option
  const classes = useStyles()

  return (
    <Grid xs={12} className={classes.optionContainer}>
      <Typography className={classes.optionTitle} onClick={action}>
        {title}
      </Typography>
    </Grid>
  )
}

export default MobileMenuItem
