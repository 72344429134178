const styles = () => ({
  navItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#003C78',
    },
  },
  dotContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dot: {
    width: 8,
    height: 8,
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: '#003C78',
    marginTop: 10,
  },
  menuItemContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    cursor: 'pointer',
    '@media (min-width: 1224px) and (max-width: 1400px)': {
      paddingLeft: 5,
      paddingRight: 5,
      paddingBottom: 2,
    },
  },
  menuItemTitle: {
    color: '#858585',
    fontSize: 16,
    '&:hover': {
      color: '#003C78',
    },
  },
  childMenuItem: {
    color: '#767272',
    '&:hover': {
      color: 'white',
    },
  },
  menuSubchild: {
    color: '#767272',
    fontSize: 14,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  menuChildContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 400,
    minWidth: 231,
  },
  submenuChildContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  arrowIcon: {
    color: '#003C78',
    width: 15,
    height: 15,
  },
})

export default styles
