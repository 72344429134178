import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const ComboBox = ({
  form: { handleBlur },
  value,
  id,
  options,
  label,
  handleChange,
  setFieldValue,
  formatLabel,
  error,
  helperText,
  disabled,
  arrowIcon = false,
  placeholder,
  InputLabelProps,
  isMultiple = false,
  hideTags = false,
  ...props
}) => {
  const [valueOption, setValueOption] = useState(isMultiple ? [] : null)

  useEffect(() => {
    const getValue = () => {
      if (value) {
        if (isMultiple) {
          return Array.isArray(value) ? value : []
        }
        return {
          name: value.name,
          id: value.id,
        }
      }
      return isMultiple ? [] : null
    }
    const initial = getValue()
    setValueOption(initial)
  }, [value, isMultiple])

  let arrOptions = []
  if (Array.isArray(options) && options.length > 0) {
    arrOptions = options.map(option => {
      const optionData = {}
      optionData.id = option.id
      optionData.name = option.name
      if (option.uf) optionData.uf = option.uf
      if (option.value) optionData.value = option.value
      if (option.user) optionData.user = option.user
      if (option.profileType) optionData.profileType = option.profileType
      return optionData
    })
  }

  return (
    <Autocomplete
      {...props}
      id={id}
      value={valueOption}
      onChange={(_, newValue) => {
        setValueOption(newValue)
        setFieldValue(id, newValue)
        if (typeof handleChange === 'function') handleChange(newValue)
      }}
      onBlur={handleBlur}
      getOptionLabel={option => (formatLabel ? formatLabel(option) : option.name || '')}
      getOptionSelected={(option, newOption) => option.id === newOption.id}
      options={arrOptions}
      disabled={disabled}
      popupIcon={arrowIcon ? <ExpandMore style={{ color: '#2D3648' }} /> : undefined}
      noOptionsText="Nenhuma opção disponível"
      multiple={isMultiple}
      renderTags={hideTags ? () => null : undefined}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          size={props.size}
          helperText={helperText}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          InputLabelProps={InputLabelProps}
          fullWidth
        />
      )}
    />
  )
}

export default React.memo(ComboBox)
