const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 40px',
    '@media screen and (min-width:320px) and (max-width:915px)': {
      padding: '20px 20px',
    },
  },
  logo: { height: '45px' },
  logoDesktop: {
    height: '60px',
    '@media (min-width: 1224px) and (max-width: 1500px)': {
      height: '40px',
    },
  },
  textContainer: { display: 'flex', color: '#FCFCFC', width: '20px' },
})

export default styles
