import { Grid, MenuItem, Typography } from '@material-ui/core'
import { PlayArrow } from '@material-ui/icons'
import React, { useState } from 'react'

const HeaderMenuItemChild = ({ child, closeDropdownMenu, classes }) => {
  const [showChildren, setShowChildren] = useState(false)

  const onClickMenuItem = () => {
    closeDropdownMenu()
    child.action()
  }

  const onClickSubmenuItem = subChild => {
    subChild.action()
    closeDropdownMenu()
    setShowChildren(false)
  }

  const onHoverMenuItem = () => {
    if (child.subChildren.length > 0) {
      setShowChildren(true)
    }
  }

  const onHoverMenuChildItem = event => {
    setShowChildren(false)
    event.stopPropagation()
  }

  return (
    <Grid>
      <MenuItem
        className={`${classes.navItem} ${classes.childMenuItem} ${classes.menuChildContainer}`}
        style={{
          backgroundColor: showChildren ? '#003C78' : '',
        }}
        onClick={onClickMenuItem}
        onMouseOver={onHoverMenuItem}
      >
        <Typography style={{ color: showChildren ? 'white' : '', fontSize: 14 }}>
          {child.label}
        </Typography>
      </MenuItem>
      <Grid onMouseLeave={onHoverMenuChildItem}>
        {child.subChildren.map(subChild => {
          return (
            <Grid>
              {showChildren && (
                <MenuItem onClick={() => onClickSubmenuItem(subChild)}>
                  <Grid xs={12} className={classes.submenuChildContainer}>
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                      <PlayArrow className={classes.arrowIcon} />
                    </Grid>
                    <Typography className={classes.menuSubchild}>{subChild.label}</Typography>
                  </Grid>
                </MenuItem>
              )}
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default HeaderMenuItemChild
