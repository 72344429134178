/* eslint-disable max-len */
import React, { useState, useContext, useEffect, useCallback } from 'react'
import {
  withStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Link,
  Divider,
  Typography,
} from '@material-ui/core'

import {
  ExitToAppOutlined,
  MenuOutlined,
  HomeOutlined,
  AssignmentOutlined,
  ArrowUpwardOutlined,
  SchoolOutlined,
  SupervisorAccountOutlined,
  FaceOutlined,
  AccountCircleOutlined,
  ArrowBackOutlined,
  AccountTreeOutlined,
  BusinessOutlined,
  PeopleOutlined,
  BarChartOutlined,
  LibraryBooksOutlined,
  BuildOutlined,
  ImportContacts,
  VpnKey,
  DynamicFeedOutlined,
} from '@material-ui/icons'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import CookieConsent from 'react-cookie-consent'
import { useSnackbar } from '../../shared/hooks'
import ROLES from '../../shared/profileRules'
import api from '../../services/api'
import UserService from '../../services/UserService'
import AuthContext from '../../contexts/AuthContext'
import { getSelectedProfile } from '../../utils/user/profileHelper'
import RegionalCoordinatorSelfDeclarationDialog from './components/RegionalCoordinatorSelfDeclarationDialog'
import SchoolManagerSelfDeclarationDialog from './components/SchoolManagerSelfDeclarationDialog/SchoolManagerSelfDeclarationDialog'

import ItemMenu from './components/ItemMenu'

import UserLogged from './components/userLogged'

import styles from './Sidebar.style'
import { useSidebar } from '../../contexts'

const Sidebar = ({ classes, history }) => {
  const {
    currentUser,
    signedIn,
    setCloseDrawerMenu,
    isSpecialNetworkManager,
    isConexia,
    isAdmFarol,
  } = useContext(AuthContext)
  const [userLogged, setUserLogged] = useState([])
  const {
    handleMenuChangeToRoute,
    menuItems1,
    setMenuItems1,
    menuItems2,
    setMenuItems2,
    menuItems3,
    setMenuItems3,
    menuItems4,
    setMenuItems4,
  } = useSidebar()
  const [collapsed, setCollapsed] = useState(false)
  const { snackbarError } = useSnackbar()
  const [selfDeclaration, setSelfDeclaration] = useState({})
  const [selfDeclarationSchoolManager, setSelfDeclarationSchoolManager] = useState({})
  const isPartnershipCollaborator = getSelectedProfile().id === 10
  const isAdm = getSelectedProfile().id === 5

  const { isNetworkManagerAt } = useContext(AuthContext)
  const isImplementador = isNetworkManagerAt === 'Agente Técnico'

  useEffect(() => {
    const { pathname } = history.location
    if (
      (UserService.isLoggedIn() && !isAdm) ||
      (UserService.isLoggedIn() && isAdm && isAdmFarol !== undefined)
    ) {
      const handleRules = menuRules => {
        return getSelectedProfile() && menuRules.includes(getSelectedProfile().id)
      }

      const menuItemDivisorHome = [
        {
          label: 'Início',
          route: '',
          active: pathname === '/dashboard',
          icon: HomeOutlined,
          role: handleRules([
            ...ROLES.ALL,
            ...ROLES.REGIONAL_COORDINATOR,
            ...ROLES.RESEARCHER,
            ...ROLES.EXAMINEE,
            ...ROLES.PARTNERSHIP_COLLABORATOR,
            ...ROLES.EXTERNAL_COORDINATOR,
          ]),
        },
      ]

      const menuItemDivisorCadastros = [
        {
          label: 'Regionais e escolas',
          route: 'regionais',
          active: pathname === '/regionais',
          icon: AccountTreeOutlined,
          role: handleRules([...ROLES.NETWORK_MANAGER]),
        },
        {
          label: 'Coordenadores de regionais',
          route: 'coordenadores',
          active: pathname === '/coordenadores',
          icon: SupervisorAccountOutlined,
          role: isImplementador ? handleRules(ROLES.NETWORK_MANAGER) : null,
        },
        {
          label: 'Gestores escolares',
          route: 'gestor-escolar',
          active: ['/gestor-escolar', '/importar-dados-cadastrais-network-manager'].includes(
            pathname,
          ),
          icon: BusinessOutlined,
          role: handleRules(ROLES.NETWORK_MANAGER),
          subMenuItems: [
            {
              label: 'Cadastro manual',
              route: 'gestor-escolar',
              active: pathname === '/gestor-escolar',
              role: handleRules(ROLES.NETWORK_MANAGER),
            },
            {
              label: 'Cadastro em lote',
              route: 'importar-dados-cadastrais-network-manager',
              active: pathname === '/importar-dados-cadastrais-network-manager',
              role: handleRules(ROLES.NETWORK_MANAGER),
            },
          ],
        },
        {
          label: 'Professores',
          route: 'professores',
          active: ['/professores', '/importar-dados-cadastrais-teacher'].includes(pathname),
          icon: SchoolOutlined,
          role: handleRules(ROLES.SCHOOL_MANAGER),
          subMenuItems: [
            {
              label: 'Cadastro manual',
              route: 'professores',
              active: pathname === '/professores',
              role: handleRules(ROLES.NETWORK_MANAGER),
            },
            {
              label: 'Cadastro em lote',
              route: 'importar-dados-cadastrais-teacher',
              active: pathname === '/importar-dados-cadastrais-teacher',
              role: handleRules(ROLES.NETWORK_MANAGER),
            },
          ],
        },
        {
          label: 'Turmas',
          route: 'turmas',
          active: ['/turmas', '/importar-dados-cadastrais-classroom'].includes(pathname),
          icon: PeopleOutlined,
          role: handleRules(ROLES.TEACHER),
          subMenuItems: [
            {
              label: 'Cadastro manual',
              route: 'turmas',
              active: pathname === '/turmas',
              role: handleRules(ROLES.NETWORK_MANAGER),
            },
            {
              label: 'Cadastro em lote',
              route: 'importar-dados-cadastrais-classroom',
              active: pathname === '/importar-dados-cadastrais-classroom',
              role: handleRules(ROLES.NETWORK_MANAGER),
            },
          ],
        },
        {
          label: 'Estudantes',
          route: 'estudantes',
          active: ['/estudantes', '/importar-dados-cadastrais-examinee'].includes(pathname),
          icon: FaceOutlined,
          role: isImplementador ? handleRules(ROLES.TEACHER) : null,
          subMenuItems: [
            {
              label: 'Cadastro manual',
              route: 'estudantes',
              active: pathname === '/estudantes',
              role: handleRules(ROLES.NETWORK_MANAGER),
            },
            {
              label: 'Cadastro em lote',
              route: 'importar-dados-cadastrais-examinee',
              active: pathname === '/importar-dados-cadastrais-examinee',
              role: handleRules(ROLES.NETWORK_MANAGER),
            },
          ],
        },
      ]

      const menuItemCadastrosExternos = [
        {
          label: 'Unidade',
          route: 'unidades',
          active: pathname === '/unidades',
          icon: AccountTreeOutlined,
          role: handleRules([...ROLES.NETWORK_MANAGER]),
        },
      ]
      const menuItemDivisorColetasAcompanhamentoPart1 = [
        {
          label: 'Avaliações',
          route: 'aplicacoes',
          active: pathname === '/aplicacoes',
          icon: AssignmentOutlined,
          role: handleRules([
            ...ROLES.TEACHER,
            ...ROLES.NETWORK_MANAGER,
            ...ROLES.REGIONAL_COORDINATOR,
          ]),
        },
        {
          label: 'Avaliações',
          route: 'aplicacoes-pesquisador',
          active: pathname === '/aplicacoes-pesquisador',
          icon: AssignmentOutlined,
          role: handleRules(ROLES.RESEARCHER),
        },
        {
          label: 'Avaliações',
          route: 'aplicacoes-respondente',
          active: pathname === '/aplicacoes-respondente',
          icon: AssignmentOutlined,
          role: handleRules(ROLES.PARTNERSHIP_COLLABORATOR),
        },
        {
          label: 'Avaliações',
          route: 'aplicacoes-estudante',
          active: pathname === '/aplicacoes-estudante',
          icon: AssignmentOutlined,
          role: handleRules([...ROLES.EXAMINEE]),
        },
        {
          label: 'Avaliações',
          route: 'aplicacoes-respondente-sa',
          active: pathname === '/aplicacoes-respondente-sa',
          icon: AssignmentOutlined,
          role: handleRules([...ROLES.RESPONDER]),
        },
        {
          label: 'Avaliação para professores',
          route: 'instrumentos',
          active: pathname === '/instrumentos',
          icon: LibraryBooksOutlined,
          role: handleRules(ROLES.RESEARCHER),
        },
      ]

      const itemBetween = {
        label: 'Avaliação para professores',
        route: 'instrumento',
        active: pathname === '/instrumento',
        icon: LibraryBooksOutlined,
        role: handleRules(ROLES.EDUCATOR_INSTRUMENT),
      }

      const menuItemDivisorColetasAcompanhamentoPart2 = [
        {
          label: 'Programas educacionais',
          route: 'programas',
          active: pathname === '/programas',
          icon: DynamicFeedOutlined,
          role: handleRules(ROLES.NETWORK_MANAGER),
        },
        {
          label: 'Parcerias',
          route: 'parceria',
          active: pathname === '/parceria',
          icon: PeopleOutlined,
          role: handleRules(ROLES.RESEARCHER),
        },
        {
          label: 'Respondentes',
          route: 'respondentes/cadastrar',
          active: pathname === '/respondentes/cadastrar',
          icon: FaceOutlined,
          role: handleRules(ROLES.RESEARCHER),
        },
        {
          label: 'Chaves de resposta',
          route: 'chave-resposta',
          active: pathname === '/chave-resposta',
          icon: VpnKey,
          role: handleRules(ROLES.RESEARCHER),
        },
        {
          label: 'Itens',
          route: 'itens',
          active: pathname === '/itens',
          icon: FormatListBulletedIcon,
          role: handleRules(ROLES.RESEARCHER),
        },
        {
          label: 'Cadernos',
          route: 'cadernos',
          active: pathname === '/cadernos',
          icon: ImportContacts,
          role: handleRules(ROLES.RESEARCHER),
        },
      ]

      const trackingDataItemCurrent = {
        label: 'Dados de acompanhamento',
        route: 'importar-dados-acompanhamento',
        active: pathname === '/importar-dados-acompanhamento',
        icon: ArrowUpwardOutlined,
        role: handleRules([...ROLES.NETWORK_MANAGER]),
      }

      const trackingDataItemSpecial = {
        label: 'Dados de acompanhamento',
        route: 'importar-dados-acompanhamento-manual',
        active: [
          '/importar-dados-acompanhamento-manual',
          '/importar-dados-acompanhamento',
        ].includes(pathname),
        icon: ArrowUpwardOutlined,
        role: handleRules([...ROLES.NETWORK_MANAGER]),
        subMenuItems: [
          {
            label: 'Cadastro manual',
            route: 'importar-dados-acompanhamento-manual',
            active: pathname === '/importar-dados-acompanhamento-manual',
            role: handleRules(ROLES.NETWORK_MANAGER),
          },
          {
            label: 'Cadastro em lote',
            route: 'importar-dados-acompanhamento',
            active: pathname === '/importar-dados-acompanhamento',
            role: handleRules(ROLES.NETWORK_MANAGER),
          },
        ],
      }

      const getTrakingDataItem = isSpecialNetworkManager
        ? trackingDataItemSpecial
        : trackingDataItemCurrent

      const menuItemDivisor3Part3 = [
        {
          label: 'Dados de acompanhamento',
          route: 'importar-respondente',
          active: pathname === '/importar-respondente',
          icon: ArrowUpwardOutlined,
          role: handleRules([...ROLES.RESEARCHER]),
        },
        {
          label: 'Acompanhamento',
          route: 'acompanhamentos',
          active: pathname === '/acompanhamentos',
          quizIcon: (
            <SvgIcon>
              <path d="M0,0h24v24H0V0z" fill="none" />
              <path
                id="target"
                // eslint-disable-next-line max-len
                d="M4,6H2v14c0,1.1,0.9,2,2,2h14v-2H4V6z M20,2H8C6.9,2,6,2.9,6,4v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4 C22,2.9,21.1,2,20,2z M20,16H8V4h12V16z M13.51,10.16c0.41-0.73,1.18-1.16,1.63-1.8c0.48-0.68,0.21-1.94-1.14-1.94 c-0.88,0-1.32,0.67-1.5,1.23l-1.37-0.57C11.51,5.96,12.52,5,13.99,5c1.23,0,2.08,0.56,2.51,1.26c0.37,0.6,0.58,1.73,0.01,2.57 c-0.63,0.93-1.23,1.21-1.56,1.81c-0.13,0.24-0.18,0.4-0.18,1.18h-1.52C13.26,11.41,13.19,10.74,13.51,10.16z M12.95,13.95 c0-0.59,0.47-1.04,1.05-1.04c0.59,0,1.04,0.45,1.04,1.04c0,0.58-0.44,1.05-1.04,1.05C13.42,15,12.95,14.53,12.95,13.95z"
                transform="translate(3 2.863)"
              />
            </SvgIcon>
          ),
          role: handleRules(ROLES.REGIONAL_COORDINATOR),
        },
        {
          label: 'Biblioteca de relatórios de devolutiva',
          route: 'biblioteca-relatorios',
          active: pathname === '/biblioteca-relatorios',
          icon: LibraryBooksOutlined,
          role: handleRules([
            ...ROLES.TEACHER,
            ...ROLES.EXAMINEE,
            ...ROLES.PARTNERSHIP_COLLABORATOR,
            ...ROLES.REGIONAL_COORDINATOR,
            ...ROLES.EXTERNAL_COORDINATOR,
            ...ROLES.DIRECTOR,
            ...ROLES.EDUCATOR_TECHNICIAN,
          ]),
        },
        {
          label: 'Relatórios',
          route: 'relatorios',
          active: pathname === '/relatorios',
          icon: BarChartOutlined,
          role: handleRules([...ROLES.REGIONAL_COORDINATOR, ...ROLES.NETWORK_MANAGER]),
        },
        {
          label: 'Integração Manual',
          route: 'integracao-manual',
          active: pathname === '/integracao-manual',
          icon: BarChartOutlined,
          role: handleRules([...ROLES.ADMIN, ...ROLES.NETWORK_MANAGER]),
        },
        {
          label: 'Relatórios de Integração',
          route: 'relatorios-integracao',
          active: pathname === '/relatorios-integracao',
          icon: BarChartOutlined,
          role: handleRules([...ROLES.ADMIN, ...ROLES.NETWORK_MANAGER]),
        },
      ]

      const userLoggedInfo = [
        {
          active: `/profile` === pathname,
          route: 'profile',
          icon: AccountCircleOutlined,
          role: handleRules(ROLES.PROFILE),
        },
      ]

      if (isAdmFarol) {
        // Preenchendo o menu do ADM Farol
        const menuItemAdmFarol = [
          {
            label: 'Agentes Técnicos e Gestores',
            route: 'admin/agentes-tecnicos-gestores',
            active: pathname === '/admin/agentes-tecnicos-gestores',
            icon: BuildOutlined,
            role: handleRules([...ROLES.ADMIN]),
          },
        ]

        setMenuItems1(menuItemDivisorHome)
        setMenuItems2(menuItemAdmFarol)
      } else {
        setMenuItems1(menuItemDivisorHome)
        setMenuItems2(menuItemDivisorCadastros)
        setMenuItems3(menuItemCadastrosExternos)

        if (
          currentUser?.regionals !== undefined &&
          currentUser?.regionals[0]?.name === 'Regional de Auto Cadastro'
        ) {
          const listMenuItemDivisor3Full = menuItemDivisorColetasAcompanhamentoPart1.concat(
            itemBetween,
            menuItemDivisorColetasAcompanhamentoPart2,
            getTrakingDataItem,
            menuItemDivisor3Part3,
          )

          setMenuItems4(listMenuItemDivisor3Full)
        } else {
          const listMenuItemDivisor3Full = menuItemDivisorColetasAcompanhamentoPart1.concat(
            menuItemDivisorColetasAcompanhamentoPart2,
            getTrakingDataItem,
            menuItemDivisor3Part3,
          )

          setMenuItems4(listMenuItemDivisor3Full)
        }
      }

      setUserLogged(userLoggedInfo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, currentUser, isAdmFarol, isAdm])

  useEffect(() => {
    if (currentUser) {
      if (currentUser.user.profiles.find(profile => profile.role === 'ROLE_REGIONAL_COORDINATOR')) {
        if (!currentUser.selfDeclarationAccepted) {
          setSelfDeclaration({ open: true, user: currentUser })
        }
      }
    }
  }, [currentUser])

  const handleAcceptSelfDeclaration = async id => {
    await api
      .patch(`regional-coordinators/accept-self-declaration/${id}`)
      .then(() => {
        currentUser.selfDeclarationAccepted = true
        localStorage.setItem('currentUser', JSON.stringify(currentUser))
        setSelfDeclaration({ open: false, user: currentUser })
      })
      .catch(() => {
        snackbarError('Falha ao aceitar Autodeclaração')
      })
  }

  const handleAcceptSelfDeclarationSchoolManager = async () => {
    if (currentUser) {
      if (currentUser.user.id) {
        await api
          .patch(`schoolmanagers/accept-self-declaration/${currentUser.user.id}`)
          .then(() => {
            currentUser.selfDeclarationAcceptedSchoolManager = true
            setSelfDeclarationSchoolManager({ open: false })
          })
          .catch(() => {
            snackbarError('Falha ao aceitar Autodeclaração')
          })
      }
    }
  }

  const checkExistsSelfDeclarationPendingSchoolManager = async () => {
    if (getSelectedProfile().name === 'Gestor Escolar') {
      await api
        .get(`schoolmanagers/verify-accept-self-declaration/${currentUser.user.id}`)
        .then(response => {
          if (response.data === true) {
            setSelfDeclarationSchoolManager({ open: true })
          }
        })
        .catch(() => {
          snackbarError('Falha ao verificar Autodeclaração')
        })
    }
  }

  useEffect(() => {
    if (currentUser) {
      if (currentUser.user.id) {
        checkExistsSelfDeclarationPendingSchoolManager()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const handleExit = useCallback(() => {
    UserService.forceLogout()
  }, [])

  return (
    <>
      <Drawer
        className={`${classes.drawer} ${collapsed && classes.collapsed}`}
        variant="permanent"
        position="fixed"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <CookieConsent
          buttonText="ENTENDI"
          location="bottom"
          cookieName="cokkiePolitica"
          expires={99999}
          style={{
            background: '#F3F3F3',
            fontFamily: 'Barlow',
            font: 'normal',
            fontSize: '16px',
            color: '#3E3E3E',
            width: '780px',
            height: '80px',
            borderRadius: '2px',
            opacity: 1,
            display: 'flex',
            justifyContent: 'center',
            left: '30%',
            position: 'fixed',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: '16px',
            marginBottom: '16px',
          }}
          buttonStyle={{
            color: '#FFFFFF',
            background: '#003C7B',
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Barlow',
            borderRadius: '4px',
            opacity: 1,
            width: '160px',
            height: '48px',
          }}
        >
          Coletamos cookies para melhorar o desempenho e a segurança da plataforma. Para mais
          informações você pode consultar a nossa{' '}
          <Link className={classes.linkStyle} href="/politicas">
            <span>Política de privacidade</span>
          </Link>
        </CookieConsent>
        {UserService.isLoggedIn() && signedIn && getSelectedProfile() && (
          <List className={`${classes.root} ${collapsed && classes.collapsed}`}>
            <div>
              {userLogged && userLogged.length > 0 && (
                <UserLogged
                  userName={
                    isPartnershipCollaborator
                      ? UserService.getPartnershipCollaboratorName()
                      : UserService.getName()
                  }
                  userProfile={
                    isSpecialNetworkManager ? 'Gestor de Dados' : getSelectedProfile().description
                  }
                  route={userLogged[0].route}
                  active={userLogged[0].active}
                  history={history}
                  collapsed={collapsed}
                  handleClick={handleMenuChangeToRoute}
                  group={isNetworkManagerAt}
                />
              )}
              {isAdmFarol ? (
                <>
                  <div>
                    {menuItems1?.length > 0 &&
                      menuItems1.map(
                        ({ label, route, active, icon, role, quizIcon, subMenuItems }) =>
                          role && (
                            <ItemMenu
                              key={label}
                              icon={icon}
                              quizIcon={quizIcon}
                              label={label}
                              route={route}
                              active={active}
                              history={history}
                              collapsed={collapsed}
                              handleClick={handleMenuChangeToRoute}
                              subMenuItems={subMenuItems}
                            />
                          ),
                      )}
                  </div>
                  <>
                    <Divider className={classes.divider} variant="fullWidth" />
                    <Typography className={classes.dividerTitle}>CADASTROS</Typography>
                  </>
                  <div>
                    {menuItems2?.length > 0 &&
                      menuItems2.map(
                        ({ label, route, active, icon, role, quizIcon, subMenuItems }) =>
                          role && (
                            <ItemMenu
                              key={label}
                              icon={icon}
                              quizIcon={quizIcon}
                              label={label}
                              route={route}
                              active={active}
                              history={history}
                              collapsed={collapsed}
                              handleClick={handleMenuChangeToRoute}
                              subMenuItems={subMenuItems}
                            />
                          ),
                      )}
                  </div>
                </>
              ) : (
                <>
                  {menuItems1 &&
                    menuItems1.length > 0 &&
                    menuItems1.map(
                      ({ label, route, active, icon, role, quizIcon, subMenuItems }) =>
                        role && (
                          <ItemMenu
                            key={label}
                            icon={icon}
                            quizIcon={quizIcon}
                            label={label}
                            route={route}
                            active={active}
                            history={history}
                            collapsed={collapsed}
                            handleClick={handleMenuChangeToRoute}
                            subMenuItems={subMenuItems}
                          />
                        ),
                    )}

                  {currentUser.user?.profiles[0].role === 'ROLE_TEACHER' ||
                  currentUser.user?.profiles[0].role === 'ROLE_SCHOOL_MANAGER' ||
                  currentUser.user?.profiles[0].role === 'ROLE_NETWORK_MANAGER' ? (
                    <>
                      <Divider className={classes.divider} variant="fullWidth" />
                      <Typography className={classes.dividerTitle}>CADASTROS</Typography>
                    </>
                  ) : null}

                  {menuItems2 &&
                    menuItems2.length > 0 &&
                    menuItems2.map(
                      ({ label, route, active, icon, role, quizIcon, subMenuItems }) =>
                        role && (
                          <ItemMenu
                            key={label}
                            icon={icon}
                            quizIcon={quizIcon}
                            label={label}
                            route={route}
                            active={active}
                            history={history}
                            collapsed={collapsed}
                            handleClick={handleMenuChangeToRoute}
                            subMenuItems={subMenuItems}
                          />
                        ),
                    )}

                  {currentUser.user?.profiles[0].role === 'ROLE_NETWORK_MANAGER' && (
                    <>
                      <Divider className={classes.divider} variant="fullWidth" />
                      <Typography className={classes.dividerTitle}>CADASTROS EXTERNOS</Typography>
                    </>
                  )}

                  {menuItems3 &&
                    menuItems3.length > 0 &&
                    menuItems3.map(
                      ({ label, route, active, icon, role, quizIcon, subMenuItems }) =>
                        role && (
                          <ItemMenu
                            key={label}
                            icon={icon}
                            quizIcon={quizIcon}
                            label={label}
                            route={route}
                            active={active}
                            history={history}
                            collapsed={collapsed}
                            handleClick={handleMenuChangeToRoute}
                            subMenuItems={subMenuItems}
                          />
                        ),
                    )}

                  <Divider className={classes.divider} variant="fullWidth" />
                  <Typography className={classes.dividerTitle}>COLETAS E ACOMPANHAMENTO</Typography>

                  {menuItems4 &&
                    menuItems4.length > 0 &&
                    menuItems4.map(
                      ({ label, route, active, icon, role, quizIcon, subMenuItems }) => {
                        if (role) {
                          if (
                            label === 'Integração Manual' ||
                            label === 'Relatórios de Integração'
                          ) {
                            // Aplica a condição apenas para "Integração Manual" e "Relatórios de Integração"
                            if (isConexia || role === '') {
                              return (
                                <ItemMenu
                                  key={label}
                                  icon={icon}
                                  quizIcon={quizIcon}
                                  label={label}
                                  route={route}
                                  active={active}
                                  history={history}
                                  collapsed={collapsed}
                                  handleClick={handleMenuChangeToRoute}
                                  subMenuItems={subMenuItems}
                                />
                              )
                            }
                          } else {
                            return (
                              <ItemMenu
                                key={label}
                                icon={icon}
                                quizIcon={quizIcon}
                                label={label}
                                route={route}
                                active={active}
                                history={history}
                                collapsed={collapsed}
                                handleClick={handleMenuChangeToRoute}
                                subMenuItems={subMenuItems}
                              />
                            )
                          }
                        }
                        return null // Ignora itens sem role
                      },
                    )}
                </>
              )}

              <ListItem
                component="button"
                onClick={handleExit}
                className={`${classes.listItem} ${collapsed && classes.listItemCollapsed}`}
              >
                <div className={classes.item} style={{ marginBottom: '' }}>
                  <ListItemIcon>
                    <ExitToAppOutlined />
                  </ListItemIcon>
                  {!collapsed && <ListItemText className={classes.listItemText}>Sair</ListItemText>}
                </div>
              </ListItem>
            </div>

            <ListItem
              id="buttonCollapseMenu"
              component="button"
              position="fixed"
              className={`${classes.listItem} ${collapsed && classes.listItemCollapsed}`}
              onClick={() => {
                setCollapsed(!collapsed)
                setCloseDrawerMenu(!collapsed)
              }}
            >
              <div className={classes.item}>
                <ListItemIcon>{collapsed ? <MenuOutlined /> : <ArrowBackOutlined />}</ListItemIcon>
                {!collapsed && (
                  <ListItemText className={classes.itemText}>Ocultar Menu</ListItemText>
                )}
              </div>
            </ListItem>
          </List>
        )}
      </Drawer>
      <RegionalCoordinatorSelfDeclarationDialog
        handleAcceptSelfDeclaration={handleAcceptSelfDeclaration}
        open={selfDeclaration.open}
        user={selfDeclaration.user}
      />
      <SchoolManagerSelfDeclarationDialog
        handleAcceptSelfDeclarationSchoolManager={handleAcceptSelfDeclarationSchoolManager}
        open={selfDeclarationSchoolManager.open}
      />
    </>
  )
}

export default withStyles(styles)(React.memo(Sidebar))
