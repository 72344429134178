import React, { useContext, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { PermIdentityOutlined } from '@material-ui/icons'
import { AuthContext } from '../../../../contexts'
import UserService from '../../../../services/UserService'
import { getSelectedProfile } from '../../../../utils/user/profileHelper'
import { useResponsiveInfo } from '../../../../shared/hooks'

const LoggedUserInfo = () => {
  const { currentUser, isNetworkManagerAt, isSpecialNetworkManager } = useContext(AuthContext)
  const { isSmallDesktop, isDesktop } = useResponsiveInfo()
  const [loggedUserName, setLoggedUserName] = useState('')
  const [loggedRole, setLoggedRole] = useState('')
  const isLoggedIn = UserService.isLoggedIn()

  const getLoggedRole = () => {
    const isImplementador = isNetworkManagerAt === 'Agente Técnico'

    if (isImplementador) {
      return 'Agente Técnico'
    }

    if (isSpecialNetworkManager) {
      return 'Gestor de Dados'
    }

    return getSelectedProfile().description
  }

  useEffect(() => {
    if (isLoggedIn && getSelectedProfile() !== undefined) {
      const isPartnershipCollaborator = getSelectedProfile().id === 10
      setLoggedUserName(
        isPartnershipCollaborator
          ? UserService.getPartnershipCollaboratorName()
          : UserService.getName(),
      )
      setLoggedRole(getLoggedRole())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isNetworkManagerAt, getSelectedProfile])

  return (
    <>
      {isLoggedIn && currentUser && (
        <Grid
          style={{
            padding: '8px 4px 8px 8px',
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            cursor: 'default',
          }}
        >
          {isDesktop && (
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <PermIdentityOutlined style={{ width: 30, height: 30, color: '#858585' }} />
            </Grid>
          )}
          <Grid>
            <Typography
              style={{ fontSize: isSmallDesktop ? '1rem' : 16, color: '#858585', marginBottom: 2 }}
            >
              {loggedUserName}
            </Typography>
            <Typography style={{ fontSize: 12, color: '#858585' }}>{loggedRole}</Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default LoggedUserInfo
