/* eslint-disable max-len */
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import HeaderMenuItem from './HeaderMenuItem'
import styles from './HeaderMenu.style'

const useStyles = makeStyles(styles)

const HeaderMenu = ({ history, menuOptions }) => {
  const classes = useStyles()

  return (
    <>
      {menuOptions?.map(option => {
        return <HeaderMenuItem option={option} classes={classes} history={history} />
      })}
    </>
  )
}

export default HeaderMenu
